.ant-layout-header {
  /*Se sobreescribe las propiedades del componente header a tráves del className ant-layout-header*/
  background: #333333 !important;
  // height: 50px !important;
  // font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  padding: 7px 50px;
  /*Se configura las img y textos que se encuentran en el header*/
  .menu-top {
    display: flex;
    &__logo {
      svg {
        height: 30px;        
      }
    }
    &__ayuda {
      color: white;
      font-size: 14px;
      text-align: right;
      width: 100%;
      .anticon {
        color: #e149a4;
      }
      button {
        color: white;
        font-size: 14px;
      }
    }
  }
  .user{
    width: 24px;
    margin-left: 15px;
    margin-right: 15px;
  }
  a{
    color: white;
  }
}

.centro_ayuda {
  font-size: 24px !important;
  vertical-align: -7px !important;
  margin-right: 5px;
}
