.ant-layout {
  background: #ffffff;
}

.buttonPrivacity {
  margin-top: 20px;
  margin-bottom: 20px;
}
hr {
  border: 1px solid #d5007f;
}
.barraUsuario {
  background: #ffffff;
  padding: 0 60px;
  text-align: right;
  * {
    font-size: 15px;
    line-height: 3;        
  }  
}
.barraBienvenida {
  padding-top: 10px;
  text-align: center;
}

.btnCerrarsesion {
  outline: none !important;
  border: none !important;
  background: none !important;
  text-decoration: underline !important;
  color: #e149a4 !important;
  &:hover {
    background: none !important;
    color: #e149a4 !important;
  }
}
