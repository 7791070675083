// .credFrente {
//   width: 550px;
//   height: 300px;
//   margin: 0 auto;
// }

// .credVuelta {
//   width: 550px;
//   height: 300px;
//   margin: 0 auto;
// }

// .foto {
//   width: 550px;
//   height: 300px;
//   margin: 0 auto;
// }

.data {
  border: 1px solid #999;
  // width: 510px !important;
  height: 400px;
  overflow: auto;
}

.data .ant-form-item-label {
  line-height: 1.4;
  text-align: left;
  white-space: initial;
}

.alert{
  margin-top: 10px;
  margin-bottom: 10px;
}
