.footerP {
  color: white !important;
  background-color: #333333 !important;
  font-size: 12px;
  width: 100%;
  & h3 {
    color: #F9DBED;
  }
  & .INElogofooter {
    float: left;
    padding: 30px 0;
    text-align: center;
    width: 280px;
    & img {
      margin: 0 auto;
      width: 140px;
    }
  }
  & .INElogofooter + div {
    display: inline-block;
    width: calc(100% - 280px);
  }
}

.footerP.ant-layout-footer {
  margin-top: 40px;
  padding: 20px 50px;
}

.footerP .ant-col:nth-child(2) {
  text-align: center;
  & span {
    color: #b5b5b5;
  }
}

.footerP .ant-col:last-child {
  text-align: right;
}

// .footerP img {
//   float: left;
//   margin-right: 15px;
// }

// .footerP span {
//   margin: 0 15px;
// }

@media screen and (max-width: 992px) {
  .footerP {
    font-size: 12px !important;
    & .INElogofooter {
      width: 160px;
      & img {
        max-width: 100%;
      }
      & + div {
        width: calc(100% - 160px);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footerP {
    & .INElogofooter {
      display: none;
      & + div {
        width: 100%;
      }
    }
  }
}