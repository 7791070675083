.ant-tabs-nav .ant-tabs-tab-active {
    background-color: #e6f7ff;
}

.modalIncidente .ant-modal-content {
    max-width: none;
    width: 450px !important;
    height: 210px !important;
}

.ant-checkbox-disabled {
    color:  #000000;
}