.recharts-responsive-container{
    tspan{
        font-size: .7rem;
        padding:10px;
    }
}

.rowListRequests {
    margin-top: 30px;
}

.cardListTitle {
    margin-top: 20px;
}

.cardSummary{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 15px;
    margin-left: 20px;
    border:1px solid #6d6d6d;
}

.pShortStyle {
    margin-bottom: 3px;
}

.rowTableRequests {
    margin-top: 20px;
}