.verification{
    text-align: center;
    padding: 80px;
}

.btnExample {
    outline: none !important;
    border: none !important;
    background: none !important;
    text-decoration: underline;
    color: #e149a4 !important;
    margin-left: -10px !important;
    font-size: 15px !important;
    &:hover {
      background: none !important;
      color: #e149a4 !important;
    }
  }