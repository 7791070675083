.center{
    text-align: center;
}

.row20px {
    margin-top: 20px;
}

.row25px {
    margin-top: 25px;
}

.row25pxwidth200 {
    margin-top: 25px;
    width: 200px !important;
}
