.row20px {
    margin-top: 20px;
}

.ultimaAct {
    float: right;
    font-weight: bold;
    font-size: 12px;
}

.fechaHoraDer {
    font-size: 12px;
    position: absolute;
    margin-top: 20px;
    right: 0;
}

.registrosDer {
    position: absolute;
    right: 0;
    font-size: 24px;
    font-weight: bold;
}

.etiquetaRegistros {
    position: absolute;
    right: 0;
}

.etiquetaExcel {
    font-weight: bold;
    font-size: 14px;
}
