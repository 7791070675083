.rowList {
    margin-top: 30px;
}

.colSpace {
    margin-top: 10px;
}

.colSpaceTotal {
    text-align: right;
}

.backReportes {
    margin-bottom: 20px;
}

.buttonReports {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: left;
    margin-left: 0px !important;
}