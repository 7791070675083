.declinaTitulo {
    padding: 20px 100px
}

.declinaAlerta {
    margin-top: 30px;
    box-shadow: 0 0 6px 3px rgba(146, 146, 146, 0.2);
    color: #333333;
    height: 100%;
    padding-top: 15px;
    transition: all 0.2s ease-in-out;
    &.mensaje {
      padding: 40px;
      h1 {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }
    img,
    svg {
      color: #faad14;
    }
    p {
      font-size: 22px;
      text-align: center;
    }
}

.declinaRow {
    margin-top: 40px;
}

.declina24px {
    font-size: 24px;
}

.declinaRowBotones {
  margin-top: 20px;
  text-align: center;
}
