  
ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    padding-left: 40px;
  }
  ol li {
    margin: 0 0 0.5rem 0;
    counter-increment: my-awesome-counter;
    position: relative;
  }
  ol li::before {
    content: counter(my-awesome-counter);
    font-size: 1rem;
    position: absolute;
    color: white;
    --size: 25px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    background: #70ADCC;
    border-radius: 50%;
    text-align: center;
  }

  ul {
    counter-reset: my-awesome-counter;
    list-style: disc;
    li {
      list-style-type: disc;
    }
    li::before {
      background: none;
      content: "";
    }
  }

  .elementoLista{
    font-size: 16px;
    // margin: 0 auto;
    max-width: 600px;
    padding: 20px 0 35px 0;
  }

  .elementoLista div {
    display: inline-block;
  }

  .elementoLista div:first-child {
    width: calc(100% - 200px);
  }

  .elementoLista div:last-child {
    text-align: center;
    width: 200px;
  }

  // .elementoLista div:last-child img {
  //   display: block;
  //   margin: 0 auto;
  // }

  // .elementoLista div:last-child img:last-child {
  //   margin-top: 10px;
  // }