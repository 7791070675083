.btnDownload {
  outline: none !important;
  border: none !important;
  background: none !important;
  text-decoration: underline;
  color: #e149a4 !important;
  margin-left: 0px !important;
  //font-size: 12px !important;
  &:hover {
    background: none !important;
    color: #e149a4 !important;
  }
}

.container {
  border: 1px solid #999;
  margin-top: 50px;
  div {
    margin-left: 15px;
    h4 {
      margin-top: 15px;
      margin-left: 20px;
    }
  }
}

/*Modificar para el de documentos*/
.home {
  margin-top: 60px;
  box-shadow: 0 0 6px 3px rgba(146, 146, 146, 0.2);
  color: #333333;
  height: 100%;
  padding: 25px 25px 16px;
  transition: all 0.2s ease-in-out;
  &.mensaje {
    padding: 40px;
    h1 {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }
  img,
  svg {
    max-width: 90%;
  }
  p {
    font-size: 18px;
    max-width: 680px;
  }
}

.matCapacitacion {
  background-color: #dcf6f9;
}

.secDownload {
  margin-top: 60px;
  box-shadow: 0 0 6px 3px rgba(146, 146, 146, 0.2);
  color: #333333;
  height: 100%;
  padding: 25px 25px 16px;
  transition: all 0.2s ease-in-out;
  svg {
    display: block;
    margin: auto;
    max-width: 90%;
  }
  h1{
    margin-top: 30px;
    min-width: 30%;
  }
}

.secDeclina {
  margin-top: 10px;
  box-shadow: 0 0 6px 3px rgba(146, 146, 146, 0.2);
  color: #333333;
  height: 100%;
  padding: 25px 25px 16px;
  transition: all 0.2s ease-in-out;
  svg {
    display: block;
    margin: auto;
    max-width: 90%;
  }
}
