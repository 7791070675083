.btnExample {
  outline: none !important;
  border: none !important;
  background: none !important;
  text-decoration: underline;
  color: #e149a4 !important;
  margin-left: -10px !important;
  font-size: 15px !important;
  &:hover {
    background: none !important;
    color: #e149a4 !important;
  }
}

.textRadio {
  font-size: 12px;
  margin-left: 22px;
}

.site-checkbox-all-wrapper {
  border-bottom: 1px solid #e9e9e9;
}

.ant-checkbox-group-item {
  display: inline-block !important;
  margin-right: 0 !important;
  line-height: 2.5 !important;
}


.btnSave{
  width: 300px !important;
}