.btn_descarga_reporte {
    background: none;
    border: none;
    color: #e149a4;
    text-decoration: underline;
}
.btn_descarga_reporte:hover {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}
.btn_descarga_reporte:hover svg {
    color: #e149a4;
}
.btn_descarga_reporte:visited {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}
.btn_descarga_reporte:focus {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}

.icono_reportes {
    color: #e149a4 !important;
    background: #ffffff !important;
}

.lista_reportes .ant-list-item-meta-avatar {
    margin-right: 0%;
}
.lista_reportes .ant-list-item-meta-content {
    margin-top: 5px;
}

.liga_reportes:visited {
    color: #000000 !important;
}
.liga_reportes:hover {
    text-decoration: underline !important;
    color: #e149a4 !important;
}