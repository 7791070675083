.ant-layout{
  background-color: rgba(0,0,0,0) !important;
}
.layout {
  padding: 10px 24px 24px;

  .content {
    background: #fff;
    padding: 24px;
    min-height: 280;
  }
}
@media (max-width: 767px) {
  .layout {
    padding: 0;
  
    .content {
      padding: 6px 10px;
    }
  }
}


.numPortal {
  font-size: 36px !important;
  color: #8143ac;
  font-weight: bold;
}

.numJE {
  font-size: 36px !important;
  color: #a867ee;
  font-weight: bold;
}
.edad1 {
  font-size: 36px !important;
  color: #46d9e9;
  font-weight: bold;
}
.edad2 {
  font-size: 36px !important;
  color: #00c9e0;
  font-weight: bold;
}
.edad3 {
  font-size: 36px !important;
  color: #008e9d;
  font-weight: bold;
}
.numAcreMujeres {
  font-size: 36px !important;
  color: #c05d61;
  font-weight: bold;
}

.numAcreHombres {
  font-size: 36px !important;
  color: #ff8c8c;
  font-weight: bold;
}
