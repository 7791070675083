.div_login {
  /*Centrar el formulario, igual se puede configurar de acuerdo a las necesidades*/
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // margin: 0 auto 0;
  // border: 1px solid #999;
  line-height: 1.15;
  padding: 40px;
  .login-form {
    /**max-width: 280px;
    padding-top: 85px;**/
    width: 100%;
    // padding: 20px 30px 0;

    &__button-container {
      text-align: center;
    }
    &__button {/*propiedades del boton*/
      height: 40px;    
      width: 100%;
      background-color: #d5007f;
      color: #ffffff;
      font-size: 16px;
      border-radius: 8px;    
      max-width: 250px;
    }
    ::placeholder {
      color: #6d6d6d !important;
      font-size: 16px;
    }
    /*Tamaño del capcha*/
    &__capcha, .rc-imageselect {
      /**transform: scale(0.65);
      transform-origin: 0 0;
      padding-left: 45%;**/
      display: block;
      margin-bottom: 24px;
    }
    &__capcha div {
      margin-left: auto;
    }
  }
  a{
    margin-bottom: 20px;
    color: #d5007f;
    font-size: 14px;    
    text-decoration: underline;
    &:hover{
      color: #e767b4;
      background-color: transparent;
      text-decoration: underline;
    }
  }
  .login-image {
    margin-bottom: 30px;
    text-align: center;
  }
  .login-image img {
    height: 53px;
  }

  .change-pass{
    text-align: right;
    margin-bottom: 24px;
  }
}

#loginSysData {
  color: #b3b3b3;
  font-size: 12px;
  text-align: center;
}

#loginSysData p {
  margin-bottom: 0.2em;
}

@media (max-width: 1200px) {
  .div_login .login-form {
    padding: 20px 0px 0px;
  }
}