.ant-tabs-ink-bar {
  background-color: #333 !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  background-color: #ededed;
  color: inherit !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #333 !important;
  font-weight: 700 !important;
}

// .ant-tabs-bar {
//   text-align: center;
// }

.convocationCont {
  border: 1px solid #6d6d6d;
  color: #333;
  margin: 15px 0;
  padding: 20px 40px;
  text-align: left;
  transition: all 0.35s ease-in-out;
  &:hover {
    background: #fcedf6;
    border: 1px solid #e149a4;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
  }
  &.INE h5:before {
    background-image: url(../../../../assets/img/INE_bullet.png);
    content: "";
    width: 16px;
    height: 10px;
    margin-right: 10px;
    margin-top: 5px;
    float: left;
  }
  button {
    background: none;
    border: none;
    outline: none;
    &:hover {
      background-color: rgba(255,255,255,0) !important;
    }
  }
  p {
    font-size: 18px;
    text-align: left;
    margin: 0px 0;
  }
  span {
    color: #6d6d6d;
  }
  .ant-col:last-child {
    color: #e51a7a;
    text-align: center;
  }
}

// .btnDescargar {
//   outline: none !important;
//   border: none !important;
//   background: none !important;
//   &:hover {
//     background: none !important;
//   }
//   div {
//     text-decoration: underline;
//     color: #e149a4 !important;
//     font-size: 12px !important;
//   }
// }

.btnDescargar {
  text-decoration: underline;
  color: rgb(235, 48, 172);
  text-align: center;
  background: none;
  border: none !important;
}
