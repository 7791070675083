.ant-table-title {
    background: rgb(80, 77, 77);
    color: white;
    font-family: "Roboto";
    font-size: 16px;
    padding: 16px 16px;
    font-weight: 500;
    text-align: center;
}

.modalCourses .ant-modal-content {
    max-width: none;
    width: 1000px !important;
    height: 570px !important;
    //left: 0 !important;
}

.modalNotifi .ant-modal-content {
    max-width: none;
    width: 500px !important;
}

.ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: inherit;
}