.rowSpace {
    margin-top: 10px;
}

.rowSpace2 {
    margin-top: 20px;
}

.rowSpace3 {
    margin-top: 30px;
}

.alertSpace {
    margin-top: 35px;
}

.modalDesacredita .ant-modal-content {
    max-width: none;
    width: 500px !important;
}

.ant-modal-body {
    color: rgba(0, 0, 0, 0.85);
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    color: rgba(0, 0, 0, 0.85) !important;
}

.ant-table-footer {
    background: rgb(80, 77, 77) !important;
    color: white !important;
    font-family: "Roboto" !important;
    font-size: 16px !important;
}

.btn_descarga_link {
    background: none;
    border: none;
    color: #e149a4;
    text-decoration: underline;
}
.btn_descarga_link:hover {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}
.btn_descarga_link:hover svg {
    color: #e149a4;
}
.btn_descarga_link:visited {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}
.btn_descarga_link:focus {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}