.etiqueta {
  color: black;
  line-height: 1.5;
}
.procesoContainer {
  border-bottom: 1px solid #999;
  color: #e149a4;
  font-size: 12px;
  margin: -10px 0 10px -24px;
  padding: 3px 8px;
  text-align: right;
  width: calc(100% + 48px);
}
.proceso {
  cursor: pointer;
  float: right;
  margin-right: 24px;
  text-decoration: underline;
  width: 300px;
}
.proceso:hover span {
  background-color: #ffeae6;
}
.anticon {
  font-size: inherit;
}

p{
  padding-top: -10px;
}
