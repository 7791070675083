/*-------------- E T I Q U E T A S   H T M L --------------*/

a:focus, a:visited{
  color: #e149a4;
}

a:hover {
  color: #d5007f;
  transition: background-size 3s ease;
}

aside.ant-layout-sider-collapsed {
  background: transparent !important;
  flex: none !important;
  min-width: 0 !important;
  width: 0 !important;
}

aside.ant-layout-sider-collapsed ul.ant-menu,
aside.ant-layout-sider-collapsed ul.ant-menu+div {
  display: none;
}
aside:not(.ant-layout-sider-collapsed) .ant-layout-sider-trigger {
  margin-left: 215px;
}

body {
  color: #333;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  margin: 0;
}

button {
  appearance: none;
  background-color: white;
  border: 1px solid #d5007f;
  border-radius: 3px;
  color: #d5007f;
  font-size: 16px;
  /* height: auto !important; */
  line-height: initial;
  outline: none;
  padding: 8px 14px;
  position: relative;
  transition: all 0.2s ease-in-out !important;
}

button:hover {
  background-color: #d5007f !important;
  color: white !important;
  cursor: pointer;
}

button:hover svg {
  filter: grayscale(1) invert(1) brightness(2);
}

button:hover .anticon:not(.anticon-close-circle) svg {
  filter: none;
}

button:not(:first-child) {
  margin-left: 14px;
}

button[disabled] {
  background-color: inherit;
  opacity: 0.6;
  pointer-events: none;
}

/* button[disabled]:hover {
  background-color: initial;
  color: initial;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto-Bold"), url("./assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto-Regular"), url("./assets/fonts/Roboto-Regular.ttf");
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}

h1 {
  font-size: 24px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}

table {
  border: 0;
  font-size: 16px;
}

thead {
  background-color: #494949;
  box-shadow: 0 3px 2px 0 #ccc;
}

thead * {
  /* background-color: #494949; */
  border: 0;
  color: white;
  /* padding: 8px 4px; */
}

thead th {
  padding: 8px 4px;
}

thead tr:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

thead tr th:not(:last-child) {
  border-right: 1px solid #555;
}

::selection {
  background-color: #f9dbed;
  color: inherit;
}

/*-------------- C L A S E S   C S S --------------*/

.ant-breadcrumb-separator{
  color: #d5007f;
}

.ant-btn:hover {
  color: #d5007f;
  background-color: #fff;
  border-color: #d5007f;
}

.ant-btn-default {
  height: 35px;
  width: 160px;
  text-align: center;
  border: 1px solid #db2491;
  background: #fff;
  color: #db2491;
  font-size: 16px;
}
.ant-btn-default:hover {
  border: 1px solid #e149a4;
  background: #e149a4;
}
.ant-btn-link{
  color: #d5007f;
}
.ant-btn-link:hover, .ant-btn-link:focus, .ant-btn-link:visited{
  color: #d5007f;
}

.ant-btn-primary {
  height: 35px;
  width: 160px;
  text-align: center;
  border: 1px solid #db2491;
  background: #db2491;
  color: #fff;
  font-size: 16px;
}
.ant-btn-primary:focus {
  box-shadow: inset 0 0 6px 5px #b7006d;
  background-color: #d5007f;
  border-color: 1px solid #d5007f;
}
.ant-btn-primary:hover {
  border: 1px solid #e149a4;
  background: #e149a4;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #389b73;
  border-color: #389b73;
}

.ant-form, .ant-form label, .ant-form-item, .ant-radio-wrapper {
  font-size: 16px;
}

.ant-layout.loggeado {
  padding: 0 50px;
}

.ant-layout-sider {
  background: #f2f2f2;
  border-right: 1px solid #999;
}
.ant-layout-sider-trigger {
  padding: 24;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #d5007f;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: 0em;
  right: 50;
  bottom: 3em;
  left: 0;
  border-radius: 50%;
  margin-top: 20px;
  width: 48px !important;
}

.ant-menu {
  background: #f2f2f2;
  padding: 80px 0px 0px;
}
.ant-menu .ant-menu {
  background: #f2f2f2;
  padding-left: 18px;
}
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-inline .ant-menu-item {
  padding-left: 16px !important;
}
.ant-menu-inline .ant-menu-item:hover{
  background-color: #fcedf6;
  color: inherit;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
  border-left: 3px solid #333;
  left: 0;
}
.ant-menu-item{
  font-size: 16px;
}
.ant-menu-item-selected {
  background: #fff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
  color: inherit;
  padding-left: 30px !important;
}
.ant-menu-submenu-selected {
  color: inherit;
}
.ant-menu-submenu-title{
  font-size: 16px;
  font-weight: 700;
}
.ant-menu-submenu-title:hover {
  background-color: #fcedf6;
  color: #333;
}
.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #333, #333) !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  line-height: 1.5em !important;
  height: auto !important;
  overflow: visible;
  padding: 6px 0;
  white-space: normal;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #ffeae6;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
  border: 1px solid #79134c;
}
.ant-modal-close {
  border: none !important;
  padding: 0 !important;
}

.modalLoginRegistro {
  max-width: 410px;
}

.ant-modal-content .ant-checkbox-wrapper {
  line-height: 1.4;
}

.ant-modal-content .div_loginUser {
  display: block;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #333;
}

.ant-radio-inner::after {
  background-color: #333;
}

.avanceProceso {
  margin-top: 35px;
}

.avanceProceso .ant-steps {
  align-items: baseline;
}

.avanceProceso .ant-steps {
  background-image: linear-gradient(#dadada, #dadada);
  background-position: 0 70px;
  background-repeat: no-repeat;
  background-size: 100% 6px;
}

.avanceProceso .ant-steps-icon {
  color: white !important;
}

.avanceProceso .ant-steps-item {
  min-width: 18%;
}

.avanceProceso .ant-steps-item-container {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}

.avanceProceso .ant-steps-item-content {
  margin: 0 auto;
}

.avanceProceso .ant-steps-item-icon {
  border: none;
  margin: 0 auto;
}

.avanceProceso .ant-steps-item-process .ant-steps-item-icon {
  background-color: #2e6e73;
}

.avanceProceso .ant-steps-item-process .ant-steps-item-description {
  color: #2e6e73 !important;
  font-weight: 700;
}

.avanceProceso .ant-steps-item-process .ant-steps-item-container:after {
  border: 8px solid transparent;
  border-top-color: #2e6e73;
  content: "";
  left: calc(50% - 8px);
  position: relative;
  top: 4px;
  width: 16px;
  z-index: -1;
}

.avanceProceso .ant-steps-item-title::after {
  content: none;
}

.avanceProceso .ant-steps-item-finish .ant-steps-item-icon,
.avanceProceso .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #5ba8ae;
}

.avanceProceso .ant-steps-item-finish .ant-steps-item-description,
.avanceProceso .ant-steps-item-wait .ant-steps-item-description {
  color: #5ba8ae !important;
}

/* Color de la tabla */
.ant-table-thead > tr > th {
  background: rgb(80, 77, 77);
  color: blanchedalmond;
  font-family: "Roboto";
  font-size: 16px;
}

.back {
  border-bottom: 1px solid;
  color: #db2491;
  cursor: pointer;
  padding-bottom: 2px;
}

.back i {
  color: inherit;
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}

.back:hover {
  border-bottom: 2px solid;
}

.back:hover i {
  transform: translateX(-5px);
  transition: transform 0.2s ease-in-out;
}

.block-ui-message p {
  font-size: 18px;
  font-weight: 700;
  text-shadow: 0 0 2px #fff, -1px 1px 2px rgba(91, 91, 91, 0.55), 0 0 20px rgba(255, 255, 255, 0.95);
}

.btn-circle {
  border-radius: 50%;
  height: 35px;
  padding: 0;
  width: 35px;
}

.btn-circle, span.btn-circle > button {
  border-radius: 50%;
  height: 35px;
  padding: 0;
  width: 35px;
}

.btn_secundario {
  background-color: white;
  color: #d5007f;
}

.btn_reportes {
  background-color: white;
  border: 1px solid #d5007f;
  color: black;
  transition: all ease-in-out;
}
.btn_reportes:hover {
  background-color: #e149a4;
  border: 1px solid #d5007f;
  box-shadow: 0px 0px 5px 1px #ff79b1;
  color: white;
}
.btn_reportes:hover svg {
  color: white;
}
.btn_reportes:visited {
  border: 1px solid #e149a4;
  color: black;
}
.btn_reportes:focus {
  border: 1px solid #e149a4;
  color: black;
}

.contCaptcha {
  padding-left: 100px;
}

.content {
  background: #fff;
  padding: 24px;
  margin: 50;
  min-height: 280;
  border: 1px solid #999;
}

.convocation {
  box-shadow: 0 0 4px 1px #dadada;
  color: #333 !important;
  max-width: 260px;
  min-width: 170px;
  padding: 20px 30px;
  text-align: center;
}

.convocation i {
  font-size: 30px;
}

.datosActReporte {
  text-align: right;
}

.datosActReporte b + div {
  font-size: 12px;
}

.datosActReporte i {
  color: #17a7e5;
  margin-left: 10px;
}

.encabezadoReporte {
  background-color: #f2f2f2;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 3px 5px -2px #333;
  margin-top: 20px;
  padding: 20px;
}

/*Input validaciones*/
.has-error .ant-input,
.has-error .ant-input:hover {
  border-color: #79134c;
}

.has-success .ant-input,
.has-success .ant-input:hover {
  border-color: #389b73;
}
/*Icon validacione*/
.has-error.has-feedback .ant-form-item-children-icon {
  color: #79134c;
}

.has-success.has-feedback .ant-form-item-children-icon {
  color: #389b73;
}

.headerPublic p {
  color: #fff;
  line-height: 1;
}

.interioresPortal h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
}

.interioresPortal h2 {
  font-size: 36px;
  font-weight: lighter;
  line-height: 1.2;
}

.interioresPortal p {
  font-size: 16px;
  max-width: 600px;
}

.interioresPortal .ant-tabs-tab {
  font-size: 30px;
}

.interioresPortal .back::after {
  content: "";
  display: block;
  margin-bottom: 30px;
}

.interioresPortal .imgCont {
  text-align: center;
}

.interioresPortal .imgCont svg {
  max-width: 90%;
}

.layout {
  background: #fff;
}

.limite-registro div.ant-row:first-child {
  margin-bottom: 26px;
}

.limite-registro div:not(.ant-row) {
  float: left;
  /* font-size: 18px !important; */
}

.limite-registro p span {
  color: #ff8c8c;
  font-size: 26px;
  font-weight: 700;
}

.limite-registro div.ant-row div:first-child {
  margin-right: 40px;
}

.login-registro {
  padding: 20px 0;
}

.login-registro a {
  color: #e149a4;
}

.login-registro a:hover {
  color: #d5007f;
}

.loginContainer {
  align-items: stretch;
  display: flex;
  padding: 60px 0;
}

.loginContainer .ant-col:nth-child(2) {
  background: #d5007f;
}

.loginContainer .ant-col:nth-child(3) {
  border: 1px solid #999;
}

.plazos {
  margin-top: 20px;
  margin-left: 20px;
}

.sesionUsuarioPub {
  padding: 12px 25px;
  text-align: right;
}
.registrate{
  font-size: 26px;
}
.loginCiudadano{
  font-size:20px;
  position: relative;
  float: right;
  text-decoration: underline;
}

.sesionUsuarioPub a,
.ant-modal a,
.elementoLista a {
  background: linear-gradient(to bottom, #d5007f 0%, #d5007f 100%);
  background-position: 0 100%;
	background-repeat: repeat-x;
  background-size: 4px 1px;
  color: #d5007f;
  padding: 2px 4px;
  text-decoration: none;
  transition: background-size .2s;
}

.sesionUsuarioPub a:hover,
.ant-modal a:hover,
.elementoLista a:hover {
  color: white;
  background-size: 4px 100%;
  text-decoration: none;
}

.react-viewer-canvas {
  cursor: move;
}

.react-viewer-icon {
  color: #e149a4;
}

.react-viewer-icon-reset {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon fill='%23E149A4' points='2.952,7.063 2.952,2.952 7.063,2.952 7.063,1.5 1.5,1.5 1.5,7.063 '/%3E%3Cpolygon fill='%23E149A4' points='10.936,2.952 15.048,2.952 15.048,7.063 16.5,7.063 16.5,1.5 10.936,1.5 '/%3E%3Cpolygon fill='%23E149A4' points='7.063,15.048 2.952,15.048 2.952,10.936 1.5,10.936 1.5,16.5 7.063,16.5 '/%3E%3Cpolygon fill='%23E149A4' points='15.048,10.936 15.048,15.048 10.936,15.048 10.936,16.5 16.5,16.5 16.5,10.936 '/%3E%3C/svg%3E") !important;
}

.react-viewer-icon-rotateLeft {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23E149A4' d='M9,2.282V0L4.944,3.042L9,6.084V3.803c3.495,0,6.338,2.843,6.338,6.338c0,3.495-2.843,6.338-6.338,6.338 c-3.495,0-6.338-2.843-6.338-6.338H1.141C1.141,14.475,4.667,18,9,18c4.334,0,7.859-3.525,7.859-7.859 C16.859,5.807,13.334,2.282,9,2.282z'/%3E%3C/svg%3E") !important;
}

.react-viewer-icon-rotateRight {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23E149A4' d='M9,2.282V0l4.057,3.042L9,6.084V3.803c-3.495,0-6.338,2.843-6.338,6.338c0,3.495,2.843,6.338,6.338,6.338 c3.494,0,6.338-2.843,6.338-6.338h1.521C16.859,14.475,13.334,18,9,18s-7.859-3.525-7.859-7.859C1.141,5.807,4.666,2.282,9,2.282z' /%3E%3C/svg%3E") !important;
}

.react-viewer-icon-zoomIn {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23E149A4' d='M18,16.89l-3.628-3.629c1.154-1.401,1.85-3.195,1.85-5.15C16.222,3.639,12.583,0,8.111,0S0,3.639,0,8.111 s3.639,8.111,8.111,8.111c1.955,0,3.749-0.695,5.151-1.851L16.89,18L18,16.89z M1.57,8.111c0-3.607,2.935-6.541,6.541-6.541 c3.608,0,6.541,2.934,6.541,6.541s-2.933,6.541-6.541,6.541C4.505,14.651,1.57,11.718,1.57,8.111z'/%3E%3Cpolygon fill='%23E149A4' points='11.773,7.326 8.896,7.326 8.896,4.448 7.326,4.448 7.326,7.326 4.448,7.326 4.448,8.896 7.326,8.896 7.326,11.773 8.896,11.773 8.896,8.896 11.773,8.896 '/%3E%3C/svg%3E") !important;  
}

.react-viewer-icon-zoomOut {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23E149A4' d='M18,16.89l-3.628-3.629c1.155-1.401,1.85-3.196,1.85-5.15C16.222,3.639,12.583,0,8.111,0S0,3.639,0,8.111 s3.639,8.111,8.111,8.111c1.955,0,3.749-0.695,5.151-1.851L16.89,18L18,16.89z M1.57,8.111c0-3.607,2.935-6.541,6.541-6.541 c3.608,0,6.541,2.934,6.541,6.541s-2.933,6.541-6.541,6.541C4.505,14.651,1.57,11.718,1.57,8.111z'/%3E%3Crect x='4.448' y='7.326' fill='%23E149A4' width='7.326' height='1.57'/%3E%3C/svg%3E") !important;
}

.react-viewer-icon {
  background-repeat: no-repeat !important;
  margin-left: 5px !important;
  margin-top: 5px !important;
  display: block !important;
  width: 30px !important;
  height: 30px !important;
}

.react-viewer-btn:hover .react-viewer-icon {
  filter: grayscale(1) invert(1) brightness(2) !important;
}

.react-viewer-icon::before {
  content: "a" !important;
  color: rgba(0,0,0,0) !important;
}

.react-viewer-mask {
  background-color: #feffd4 !important;
  border: 1px solid #999 !important;
}

.react-viewer-toolbar {
  float: right;
  height: 100% !important;
  padding: 0 10px 10px 0 !important;
  width: 40px;
}

.react-viewer-toolbar li {
  background-color: white;
  border: 2px solid #e149a4;
  border-radius: 50% !important;
  height: 32px !important;
  line-height: 30px !important;
  margin-bottom: 4px;
  width: 32px !important;
}

[data-key="rotateRight"] {
  display: none !important;
  /* visibility: hidden !important; */
}

.react-viewer-toolbar li:hover {
  background-color: #e149a4 !important;
}

.react-viewer-toolbar li:hover .react-viewer-icon {
  color: white !important;
}

.rellenaHeight {
  align-items: stretch;
  display: flex;
}

.reporteResumen {
  font-size: 21px;
  text-align: right;
}

.reporteResumen p {
  color: #17a7e5;
  font-size: 36px;
  font-weight: 700;
  line-height: initial;
  margin-bottom: 0%;
}

.sesionUsuarioPub a:first-of-type + span {
  margin: 0 10px;
}

.vinculoGlosario {
  background-color: #fff;
  position: sticky;
  padding: 10px 0;
  top: 0;
  z-index: 999;
}

.vinculoGlosario a {
  background-color: #ff8c8c;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 20px;
  margin-right: 0.4em;
  text-align: center;
  width: 20px;
}

.vinculoGlosario a:hover {
  background-color: #d5007f;
  transition: all 0.25s ease-in-out;
}

/*-------------- S E L E C T O R E S   H T M L --------------*/


/*-------------- M E D I A   Q U E R Y S --------------*/

@media (max-width: 1453px) {
  .headerPublic h1 {
    font-size: 39px;
    line-height: 1.1;
  }
}

@media (max-width: 1236px) {
  .headerPublic h1 {
    font-size: 33px;
    line-height: 1.1;
  }
}

@media (max-width: 1200px) {
  .rellenaHeight {
    display: block;
  }
}

@media (max-width: 992px) {
  .credFrente,
  .credVuelta,
  .foto {
    max-height: 300px;
  }
  .data {
    height: auto;
  }
  .react-viewer-inline {
    min-height: 180px;
  }
  .headerPublic.ant-layout-header {
    padding-right: 0;
  }
  /* .rellenaHeight {
    display: block;
  } */
}

@media (max-width: 768px) {
  .ant-layout.loggeado {
    padding: 0 15px;
  }
  .ant-layout-content {
    padding: 0 20px !important;
  }
  .avanceProceso .ant-steps-item {
    display: flex;
    margin-right: 0;
    min-width: 0;
  }
  .avanceProceso .ant-steps-item-active {
    min-width: 150px;
  }
  .avanceProceso .ant-steps-item-wait .ant-steps-item-content,
  .avanceProceso .ant-steps-item-finish .ant-steps-item-content {
    display: none;
  }
  .barraUsuario {
    padding: 0 15px;
  }
  .elementoLista div:first-child {
    width: 100%;
  }
  .headerPublic h1 {
    margin-top: 7px;
  }
  .headerPublic.ant-layout-header {
    height: auto !important;
    padding: 7px 30px;
  }
  .headerPublic #logoINE_public {
    margin-top: 0;
  }
  .interioresPortal .imgCont svg {
    max-height: 180px;
  }
}

@media (max-width: 576px) {
  .ant-divider-horizontal.ant-divider-with-text-center::after,
  .ant-divider-horizontal.ant-divider-with-text-center::before {
    width: unset;
  }
  .ant-divider-inner-text h2 {
    line-height: 1.2;
    white-space: initial;
  }
  .headerPublic.ant-layout-header {
    padding-right: 0;
  }
  .headerPublic #logoINE_public {
    margin-right: 20px;
  }
  .interioresPortal .ant-tabs-tab {
    font-size: 24px;
    margin: 0 16px 0 0;
    padding: 12px 8px;
  }
  .login-registro * {
    font-size: 16px;
  }
  .login-registro span {
    display: none;
  }
}

.listaPortal ul {
  list-style: none;
}

.listaPortal li::before {
  content: "\2022";
  color:  #e149a4;
  /* font-weight: bold; */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

@media (max-width: 480px) {
  .avanceProceso {
    margin-top: 0;
  }
  .avanceProceso .ant-steps {
    background-image: none;
  }
  .avanceProceso .ant-steps-item:not(.ant-steps-item-active),
  .avanceProceso .ant-steps-item-tail::after {
    display: none;
  }
  /* .avanceProceso .ant-steps-item-wait .ant-steps-item-tail,
  .avanceProceso .ant-steps-item-wait .ant-steps-item-content,
  .avanceProceso .ant-steps-item-finish .ant-steps-item-tail,
  .avanceProceso .ant-steps-item-finish .ant-steps-item-content {
    display: none;
  } */
  .avanceProceso .ant-steps-item-active .ant-steps-item-icon {
    margin: 0 auto;
    padding: 0 8px;
    width: auto;
  }
  .avanceProceso .ant-steps-item-active .ant-steps-icon::after {
    content: "de 5";
    margin-left: 4px;
  }
  .barraUsuario {
    padding: 10px 6px;
  }
  .barraUsuario strong {
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .barraUsuario * {
    font-size: 14px;
    line-height: 1;
  }
  .btnCerrarsesion {
    min-height: auto;
    padding: 4px 8px;
  }
  .interioresPortal .ant-tabs-tab {
    font-size: 20px;
    margin: 0 2px 0 0;
  }
}

@media (max-width: 424px) {
  .contCaptcha {
    padding-left: 30px;
  }
}