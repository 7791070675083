.site-card-border-less-wrapper {
  box-shadow: 0 0 9px 1px rgba(146, 146, 146, 0.2);
  color: #333333;
  height: 100%;
  padding: 15px 25px 25px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 30px;
  span,
  .title {
    text-align: center;
    font-weight: bold;
  }
  svg {
    max-width: 90%;
  }
  .btn_Course {
    max-width: 100%;
    width: 100%;
  }
}

.encabezado {
  margin-top: 30px;
  div {
    margin-top: 10px;
    h1 {
      margin-top: 6px;
    }
  }
}

.texto_parrafo {
  text-align: justify;
}

.material {
  margin-top: 0px;
  margin-bottom: 15px;
}

.imgMaterial {
  display: block;
  margin: auto;
  width: 50%;
  height: auto;
}

.site-card-consulta {
  margin-top: 15px;
  box-shadow: 0 0 10px 1px rgba(146, 146, 146, 0.2);
  color: #333333;
  height: 100px;
  padding: 5px 0px 10px;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
  .btnDownload {
    font-size: 30px;
  }
}

.imagen {
  height: auto;
  max-width: 100%;
  display: block;
  margin: auto;
}

.title {
  display: block;
  margin: auto;
}

.bold {
  font-weight: bold;
}

.matApoyo {
  color: #e149a4;
  margin-bottom: 20px;
  margin-top: 0px;
}

.matTitle {
  color: gray;
}
.btnMaterial {
  display: inline !important;
  border: 0 !important;
  padding: 0;
  margin: 0;
  text-decoration: underline !important;
  background: none;
  color: #e149a4;
  font-size: 1em;
  line-height: 1em;
}

.btnMaterial i {
  color: inherit;
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}

.btnMaterial:hover {
  background-color: #fff !important;
  color: #e149a4 !important;
}

.btnMaterial:hover i {
  transform: translateX(-5px);
  transition: transform 0.2s ease-in-out;
}

.video {
  width: 70% !important;
  height: 500px !important;
  padding: 0 !important;
  display: block;
  margin: auto;
}

.visorImage{
  display: block;
  margin: auto;
  width: 50%;
  height: auto !important;  
}
