.recovery{
    text-align: center;
}

.textRadio {
    font-size: 12px;
    margin-left: 22px;
}

.btnExample {
    outline: none !important;
    border: none !important;
    background: none !important;
    text-decoration: underline;
    color: #e149a4 !important;
    margin-left: -10px !important;
    font-size: 15px !important;
    &:hover {
      background: none !important;
      color: #e149a4 !important;
    }
}

.sectionBox{
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #17A7E5;
    border-left: 5px solid #17A7E5;
    color: black;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}