.headerPublic{

  a{
    border-bottom: 0 solid white;
    color: white;
    transition: border-bottom 0.15s ease-in-out ;
  }
  a:hover {
    border-bottom: 10px solid white;
    transition: border-bottom 0.15s ease-in-out ;
  }
  h1{
    color:#fff;
    margin-bottom: 0;
    font-size: 42px;
  }
  svg{
    height: 36px;
  }

  #logoINE_public {
    float: left;
    margin-right: 30px;
    width: 120px;
    margin-top: 15px;
  }

  #logoINE_public svg {
    max-width: 100%;
  }

  #logoINE_public+div {
    float: left;
    width: calc(100% - 150px);
  }

  @media (max-width: 768px) {
    #logoINE_public {
      width: 80px;
    }
    #logoINE_public+div {
      width: calc(100% - 110px);
    }
    #logoINE_public+div h1 {
      font-size: 18px;
    }
  }
}

.headerPublic.ant-layout-header {
  /*Se sobreescribe las propiedades del componente header a tráves del className ant-layout-header*/
  background: #9159CB !important;
  height: 100px !important;
  padding: 10px 50px;;
  /*Se configura las img y textos que se encuentran en el header*/
  .menu-top {
    display: flex;
    &__logo {
      svg {
        height: 70px;        
      }
    }
    &__ayuda {
      text-align: right;
      p {
        color: rgb(255, 255, 255);
      }
    }
  }
  .user{
    width: 24px;
    margin-left: 15px;
    margin-right: 15px;
  }

}
