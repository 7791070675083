.video h1 {
  text-align: center;
}

.video video {
  display: block;
  margin: 0 auto;
  max-width: 90%;
}

.card-menu {
  color: #333333;
  margin: 0 auto;
  padding: 25px 25px 36px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #fcedf6;
    box-shadow: 0 0 6px 3px #f3b6da;
  }
  width: 90%;
  h3 {
    padding-bottom: 8px;
  }
  img,
  svg {
    max-width: 90%;
  }
  p {
    font-size: 14px;
    text-align: center;
    text-align: justify;
    line-height: 20px;
  }
}

.sectionMenu {
  margin-top: 30px;
}
.titulo {
  padding-left: 45px;
  font-weight: bold;
  line-height: 18px;
  font-size: 16px;
}

.numero {
  background: #ff8c8c;
  border-radius: 50%;
  color: white;
  height: 36px;
  line-height: 2;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
  width: 36px;
}
.menuPortal {
  padding: 0 30px 0 0;
  .ant-menu {
    background: #ffffff !important;
    padding: 50px 0px 0px !important;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px !important;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    border-bottom: 1px solid #b6b6b6 !important;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-left: 0;
    content: "\025ba";
    font-family: Arial, Helvetica, sans-serif;
  }
}
/* 
.ant-menu{
    background: #ffffff !important;
    padding: 50px 0px 0px !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0px !important;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    border-bottom: 1px solid #333333 !important;
} */
.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  background-color: #f9dbed;
}
.link {
  text-decoration: underline;
  color: rgb(235, 48, 172);
  text-align: center;
  &:hover {
    color: #e149a4;
  }
}
a:hover .card-menu-home {
  background: #fcedf6;
  box-shadow: 0 0 6px 3px #f3b6da;
}
.card-menu-home {
  box-shadow: 0 0 6px 3px rgba(146, 146, 146, 0.2);
  color: #333333;
  height: 100%;
  padding: 25px 25px 36px;
  transition: all 0.2s ease-in-out;
  img,
  svg {
    max-width: 90%;
  }
  p {
    font-size: 16px;
  }
}

.subTitulo {
  font-size: 26px !important;
  font-weight: 300;
  // line-height: 43px;
  margin-bottom: 0;
}

.canCiudadano {
  font-size: 36px !important;
  color: #06cfc2;
  font-weight: bold;
}

.canOrg {
  color: #9159cb;
  font-size: 36px !important;
  font-weight: bold;
  margin-bottom: 0;
}
.canMayor {
  font-size: 36px !important;
  color: #d8521d;
  font-weight: bold;
}

.encabezado {
  font-size: 16px;
  line-height: 18px;
  text-align: left !important;
  div {
    font-size: 12px;
    line-height: 18px;
  }
}

.aviso {
  box-shadow: 0 0 6px 3px rgba(255, 141, 140, 0.75);
}

@media (min-width: 768px) {
  .canCiudadano {
    margin-bottom: 0;
  }
}