.rowListRequests {
    margin-top: 30px;
}

.rowTableRequests {
    margin-top: 20px;
}

.colSpace {
    margin-top: 10px;
}

.cardSummary{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 15px;
    margin-left: 0px;
    margin-top: 20px;
    border:1px solid #6d6d6d;
}

.pShortStyle {
    margin-bottom: 3px;
}