// .back {
//   text-decoration: underline;
//   color: rgb(235, 48, 172);
//   &:hover{    
//     color: #e149a4;
//   }
// }
// .plazos {
//   margin-top: 20px;
//   margin-left: 20px;
// }
.colDocs {
  padding-top: "20px";
  padding-left: "20px";
  padding-right: "20px";
  padding-bottom: "20px";
}
.ant-checkbox-group-item {
  display: inline-block !important;
  margin-right: 0 !important;
  line-height: 2.5 !important;
}
.textRadio {
  font-size: 12px;
  margin-left: 22px;
}